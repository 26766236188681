import type { IStorageHelperOptions } from 'o365.modules.StorageHelpers.ts';
import localStorage from 'o365.modules.StorageHelpers.ts';
/**
 * Helper class for versioning stored items based on a generated hash
 */
export default class VersionedStorage<T1, T2> {
    /** Baseline values used to generate the current version hash */
    private _baselineValues: T2;
    /** Local storage id */
    private _id: string;
    /** Current hash of this storage */
    private _hash: string|null = null;
    /** Custom hashing function */
    private _hashFunction?: (pBaseline: T2) => string;
    private _storageOptions?: IStorageHelperOptions;

    get id() { return this._id; }

    constructor(pOptions: {
        /** Base value used for generating hash */
        baseline: T2,
        /** Entry key */
        id: string
        /** Optional custom hashing function */
        hashFunction?: (pBaseline: T2) => string
        /** Local storage options */
        storageOptions?: IStorageHelperOptions 
    }) {
        this._baselineValues = pOptions.baseline;
        this._id = pOptions.id;
        this._hashFunction = pOptions.hashFunction;
        this._storageOptions = pOptions.storageOptions;
    }

    /**
     * Get stored value
     * If stored hash differs from curret one will return null
     */
    getStoredValue(): T1|null {
        const storedItem = localStorage.getItem(this.id, this._storageOptions);
        if (storedItem) {
            try {
                const parsedItem: IStoredVerionItem<T1> = JSON.parse(storedItem);
                const hash = this._getHash();

                if (parsedItem.hash !== hash) {
                    localStorage.removeItem(this.id, this._storageOptions);
                    return null;
                } else {
                    return parsedItem.item;
                }
            } catch (ex) {
                console.error(ex);
                return null;
            }
        }

        return null;
    }

    /** Store value */
    storeValue(pValue: T1) {
        if (pValue == null) {
            localStorage.removeItem(this.id, this._storageOptions);
            return;
        }

        const item = JSON.stringify({
            hash: this._getHash(),
            item: pValue
        });
        localStorage.setItem(this.id, item, this._storageOptions);
    }

    private _getHash() {
        if (!this._hash) {
            if (this._hashFunction) {
                this._hash = this._hashFunction(this._baselineValues);
            } else {
                this._hash = JSON.stringify(this._baselineValues); 
            }
        }

        return this._hash;
    }

}

interface IStoredVerionItem<T> {
    hash: string;
    item: T
}